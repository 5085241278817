let config = {
  default: {
    partnerName: process.env.VUE_APP_PARTNER_NAME,
    i18n: {
      locale: process.env.VUE_APP_I18N_LOCALE,
      fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE
    },
    api: {
      basePath: "api"
    }
  },

  development: {
    api: {
      host: "http://localhost",
      port: 3001
    }
  },

  production: {
    api: {
      host: "https://connect.sasa.solutions"
    }
  }
};

if (process.env.NODE_ENV === "production") config = mergeWithDefaults(config.default, config.production);
else config = mergeWithDefaults(config.default, config.development);

// Define dynamic config values here.
config = prepareRuntimeConfig(config)
config = prepareApiConfig(config)

function prepareRuntimeConfig(config) {
  config["runtime"] = {
    version: require('../package.json').version,
    isProduction: process.env.NODE_ENV === "production",
    isDevelopment: process.env.NODE_ENV === "development",
    basePath: "/"
  }

  return config;
}

function prepareApiConfig(config) {
  let port = null;

  if (config.api.hasOwnProperty('port')) port = `:${config.api.port}/`;
  else port = "/";

  config.api["baseUrl"] = `${config.api.host}${port}${config.api.basePath}/`;

  return config;
}

function mergeWithDefaults(defaults, config) {
  const result = { ...defaults };

  for (let key in config) {
    if (config[key] instanceof Object && defaults[key] instanceof Object) {
      result[key] = mergeWithDefaults(defaults[key], config[key]);
    } else {
      result[key] = config[key];
    }
  }

  return result;
}

const AppConfig = config;
Object.freeze(AppConfig);
module.exports = AppConfig;