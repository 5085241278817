<template>
    <v-input :rules="[requiredRule]" v-model="verificationUuid" class="pa-2" style="width: 100%;">
      <v-card style="width: 100%;" :loading="!verificationUuid" loader-height="4" elevation="4">
  
        <template slot="progress">
          <v-progress-linear color="red" indeterminate v-if="acquisitionStarted"></v-progress-linear>
        </template>
  
        <!-- <v-img height="220px" :src="image" v-if="image" /> -->
        <v-icon v-if="done" class="h1">mdi-fingerprint</v-icon>
        <v-img height="220px" v-if="acquisitionStarted && !image" />
  
        <v-card-actions>
          <v-btn :color="(done ? 'green' : 'error')" block @click="capture" :disabled="done">
            {{done ? "CAPTUERED" : "CAPTURE"}}
          </v-btn>
        </v-card-actions>
  
        <!-- <v-btn id="start" @click="capture">start</v-btn>
        <v-btn id="start" @click="onSamplesAcquired">test</v-btn> -->
  
      </v-card>
    </v-input>
  </template>
  
  
  <script>
    import prodDebug from "@/mixins/prodDebug";
    import lookups from "@/mixins/lookups";
    import { Base64 } from '@digitalpersona/core';
    import VInput from 'vuetify/lib/components/VInput/VInput.js';
    import { FingerprintReader, SampleFormat } from '@digitalpersona/devices';

    const AUTHENTICATION_TYPE_IDENTIFIER = '__fingerprint__';
  
    export default {
  
      props: {
        value: {
          type: String,
          required: false,
        },
        user: {
          type: Boolean,
          default: true,
        },
        customer: {
          type: Object,
          required: false,
        },
        msisdn: {
          type: String,
          required: false,
        },
        loginCall: {
          type: Boolean,
          default: false,
        }
      },
  
      data: () => ({
        sample: undefined,
        unavailable: false,
        connected: false,
        reader: undefined,
        acquisitionStarted: false,
        authCode: undefined,
        authCheckInterval: undefined,
        verificationUuid: undefined,
        progress: undefined,
        sequenceNo: undefined,
        done: false,
      }),
  
      computed: {
        image() { return (this.sample ? "data:image/png;base64," + Base64.fromBase64Url(this.sample) : undefined); },
        strategy() {
          let  r = undefined;
          if (this.user) {
            r = 'user';
          } else {
            if (this.customer) {
              r = 'customer';
            } else if (this.msisdn) {
              r = 'msisdn';
            }
          }
          return r;
        },
        geolocation() {
          return (this.$store.getters.geolocation ? this.$store.getters.geolocation.coords : {});
        },
        appVersion() {
          return this.$store.getters.appVersion
        }
      },
  
      watch: {
        authCode(to) {
          console.log("watched authcode: ", to)
          // if (this.loginCall) {
          //   this.$emit('input', this.authCode);
          // } else {
          //   this.$emit('input', JSON.stringify({Customer: to, sequenceNO: this.sequenceNo}));
          // }
        },
        sample(to) {
          console.log('acquired');
          console.log("is true: ", this.loginCall)
          if (this.sample) {
            if(this.loginCall) {
              let params = {
                sample: this.sample,
                domain: window.location.hostname,
                app_version: this.appVersion,
                latitude: this.geolocation.latitude,
                longitude: this.geolocation.longitude,
                live: true,
                login: true,
                identifier: AUTHENTICATION_TYPE_IDENTIFIER
              }
              this.done = true
              // this.remotePost('fingerprint_login', params).then( authCode => {
              //   console.log("field agent cuptured info: ", authCode)
              //   this.authCode = authCode;
              //   // this.progress = "start"
              // } ).catch(e => this.authCode = null);
              this.remotePost('fingerprint_identify_two', params).then( authCode => {this.authCode = authCode.authCode; this.sequenceNo = authCode.sequenceNo; this.progress = "start"} ).catch(e => this.authCode = null);
              this.done = false
            } else {
                let params = {
                sample: this.sample,
                strategy: this.strategy,
                msisdn: this.msisdn,
                customer_uuid: (this.customer || {}).uuid,
                live: true,
              }
              this.done = true
              this.remotePost('fingerprint_identify_two', params).then( authCode => {this.authCode = authCode.authCode; this.sequenceNo = authCode.sequenceNo; this.progress = "start"} ).catch(e => this.authCode = null);
              this.done = false
            }
          }
        },

        progress(to) {
          let count = 0
          if((![null, undefined, "start"].includes(to) && this.authCheckInterval) || count >= 20 ) {
            clearInterval(this.authCheckInterval);
          }
          else if(to === 'start') {
            if (this.authCheckInterval) {
              clearInterval(this.authCheckInterval);
              this.authCheckInterval = null
              count = 0
            }
            this.authCheckInterval = setInterval(() => {
              count = count + 1
              console.log("count: ", count)
              if(![null, undefined, "start"].includes(this.progress) || count >= 20) {
                if(count >= 20) {

                  this.$emit('input', JSON.stringify(this.verificationUuid))
                }
                clearInterval(this.authCheckInterval);
                this.authCheckInterval = null
                this.progress = undefined
                count = 0
              }

              let params = { uuid: this.authCode }

              if (this.loginCall) {
                params = {
                  ...params,
                  domain: window.location.hostname,
                  app_version: this.appVersion,
                  login: this.loginCall,
                  latitude: this.geolocation.latitude || undefined,
                  longitude: this.geolocation.longitude  || undefined,
                  identifier: AUTHENTICATION_TYPE_IDENTIFIER
                }
              }

              this.remoteLookup('getFingerprintAuth', params)
              .then( results => {
                if(results != null) {
                  console.log("results: ", results)
                  this.progress = results.done_at
                  this.verificationUuid = results.customer
                  this.$emit('input', this.verificationUuid)
                }
                console.log("progress ", this.progress)
              } );
            }, 1000)
          }
        },
      },
  
      methods: {
  
        cheat() {
          this.sample = (new Date()).getTime().toString();
        },
  
        requiredRule(value) {
          let r = [
            v => !!((v || '').trim()) || this.$t('required'),
          ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
          return ( typeof(r)==='string' ? r : true );
        },
  
        onSamplesAcquired(s) {
          this.sample = s.samples[0];
        },
  
        startCapture() {
          console.log('start capture');
          if (this.acquisitionStarted) return;
          // this.reader = new FingerprintReader();
          var currentFormat = SampleFormat.PngImage;
          console.log("reader: ", this.reader);
          console.log("acquisition: ", this.acquisitionStarted);
          this.reader.startAcquisition(currentFormat).then(() => {
            this.acquisitionStarted = true
            console.log("starting the capture process: ")
            //Disabling start once started
            // this.disableEnableStartStop();
          });
        },

        checkVerificationProgress() {
          console.log("called interval")
          this.remoteLookup('getFingerprintAuth', { uuid: this.authCode })
          .then( results => {
            if(results != null) {
              this.progress = results.doneAt
              this.verificationUuid = results.customer
            }
          } );
        },

        capture() {
          console.log("login call: ", this.loginCall)
          fetch("http://localhost:15896/api/CloudABISV12Captures/Fingerprint", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                    "CaptureType": "SingleCapture",
                    "SingleCaptureMode": "RightFingerCapture",
                    "QuickScan": "DISABLE",
                    "CaptureOperationName": "VERIFY",
                    "DeviceName": "DigitalPersona",
                    "CaptureTimeOut": 120,
                    "LeftFingerPosition": "RightThumb",
                    "RightFingerPosition": "RightThumb"
                  })
          }).then(response => response.json())
          .then(response => {
            let base64 = response.Images.Fingerprint[0].Base64Image
            this.sample = base64
            // this.done = true;
            // this.remotePost('fingerprint_identify', params).then( authCode => {this.authCode = authCode.customer; this.sequenceNo = authCode.sequenceNo} ).catch(e => this.authCode = null);
          }).catch(error => console.log("this is error: ", error))
          this.done = false;
        }
        

      },
  
  
  
      mounted () {
  
        if (this.production) {
          console.log('start scanning');
          // this.startCapture();
        }
        
      },
  
      mixins: [prodDebug, lookups],
  
    }
  </script>
  