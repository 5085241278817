import AppConfig from "~/config";


export function backendLookupUrl() {
    const path = "app/logError/"
    return `${AppConfig.api.baseUrl}${path}`
}

export function serializeLookupParameters(params) {
    var str = [];
    for (var p in params)
        if (params.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
        }
    return str.join("&");
}

export function remoteLookupFunc(lookupUrl, lookupType, lookupApiKey, params) {
    return fetch(lookupUrl + lookupType , {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Authorization': lookupApiKey,
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(params)
    })
        .then(response => {
        if ([200, 201, 202].includes(response.status) ) {
            return response.json()
        } else {
            throw(response)
        }

    })
        .catch(error => {
            throw(error)
        });
}

export function getDeploymentRelease() {
    return fetch('/deployment-release.json' , {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
    })
        .then(response => {
        if ([200, 201, 202].includes(response.status) ) {
            return response.json()
        } else {
            throw(response)
        }

    })
        .catch(error => {
            throw(error)
        });
}

export function getDomainInfo() {
    const hostname = window.location.hostname;
    const hash = window.location.hash
    return {
        domain:hostname.split('.')[0],
        hash: hash
    };
}

