import AppConfig from "~/config";

export default {

    data: () => ({
      gpsHandle: undefined,
      gpsErrorStatus: undefined,
      gpsAccuracy: null,
      gpsCoords: undefined,
      gpsAcceptableAccuracy: 10,
      waitingForGps: true,
    }),

    computed: {
      gpsOkay() { return (this.gpsAccuracy || 10000) < this.gpsAcceptableAccuracy; },

      gpsProximity() {
        let r = 0;
        if (this.gpsAccuracy) { r = (10/this.gpsAccuracy)*100; }
        return Math.round(r);
      },

      gpsRoundedAccuracy() {
        let r = undefined;
        if (this.gpsAccuracy) {
          if (this.gpsAccuracy < 100) {
            r = Math.round(this.gpsAccuracy*10.0)/10.0;
          } else {
            r = Math.round(this.gpsAccuracy);
          }
        }
        return r;
      }
    },

    watch: {
      gpsAccuracy: function() {
        if (this.waitingForGps && this.gpsAccuracy) {
          this.waitingForGps = (this.gpsAccuracy > this.gpsAcceptableAccuracy);
        }
      },
    },

    methods: {

      gpsUpdateInternal(pos) {
        this.gpsErrorStatus = undefined;
        const coords = pos.coords;
        let latLong = { accuracy: coords.accuracy };
        if (AppConfig.runtime.isProduction) {
          latLong.latitude = coords.latitude;
          latLong.longitude = coords.longitude;
        } else {
          latLong.latitude = coords.latitude + Math.random()/1000.0 - 0.0005;
          latLong.longitude = coords.longitude + Math.random()/1000.0 - 0.0005;
        }
        if (this.gpsOkay) { this.gpsUpdate(latLong); }
        this.gpsCoords = coords;
        this.gpsAccuracy = coords.accuracy;
      },

      gpsError(err) {
        this.gpsErrorStatus = err.message;
      },

    },

    mounted() {
      let options = { enableHighAccuracy: true, };
      this.gpsHandle = navigator.geolocation.watchPosition(this.gpsUpdateInternal, this.gpsError, options);
    },

    beforeDestroy() {
      navigator.geolocation.clearWatch(this.gpsHandle);
    },

}