<template>
  <div style="background-color: #f7f7f7;" class="pa-4">
    <div class="mb-2" align="center">
      <v-img max-width="300px" contain src="@/assets/sbg.jpeg" />
    </div>
    <div align="center">
      <code v-if="prod">{{version}}</code>
      <code v-else>Development</code>
    </div>
  </div>
</template>

<script>

import AppConfig from "~/config";

export default {
  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode; },
    version() { return this.$store.getters.appVersion; },
    prod() { return (AppConfig.runtime.isProduction); },
    language() { return this.$store.getters.settings.language; },
  },
  mounted() { this.$i18n.locale = this.language; }
};
</script>
